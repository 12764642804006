import React from "react"
import {Link} from "gatsby"
import ThemeChanger from "../components/themeChanger"

const Navigation = (props) => (
  <nav className="navigation"> 
    <ThemeChanger/>
  </nav>
  
);

export default Navigation;